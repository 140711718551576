import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../../../components/Modal';
import {
  Button,
  OutlineButton,
  // eslint-disable-next-line import/no-useless-path-segments
} from '../../../../features/authentication/components/Button';

function ConfirmStandUpModal({
  className,
  isOpen,
  isSubmitting,
  onClose,
  onConfirm,
  title,
  subtitle,
}) {
  function onSubmit() {
    onConfirm();
  }

  return (
    <Modal className={className} isOpen={isOpen} onClose={onClose}>
      <div className="container">
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
        <div className="btn-action">
          <OutlineButton className="cancel-btn" onClick={onClose}>
            Cancel
          </OutlineButton>
          <Button
            className="confirm-btn"
            onClick={onSubmit}
            disabled={isSubmitting}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
}

const StyledConfirmStandUpModal = styled(ConfirmStandUpModal)`
  max-width: 420px;
  width: 90vw;
  position: relative;

  > .close {
    display: none;
  }

  .close-btn {
    position: absolute;
    background: #c1c3ca;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 4px;
    cursor: pointer;

    svg {
      color: white;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #c1c3ca;
    }
  }

  .container {
    > .image {
      height: 215px;
      width: fit-content;
      margin-bottom: 30px;
    }

    > .title {
      color: #333;
      font-size: 22px;
      margin-bottom: 20px;
      font-weight: bold;
    }

    > span {
      color: #333;
      font-size: 16px;
      margin-bottom: 20px;
    }

    > .btn-action {
      display: flex;
      margin-top: 40px;
      justify-content: flex-end;

      > .confirm-btn {
        max-width: 120px;
        width: 100%;
        padding: 12px 0;

        &:disabled {
          background: #adadad;
          cursor: not-allowed;
          border: none;
        }
      }

      > .cancel-btn {
        border: none;
        color: #c1c3ca;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
`;

export { StyledConfirmStandUpModal as ConfirmStandUpModal };
