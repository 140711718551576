import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Calendar } from 'components/Calendar';
import { DeleteTicketModal } from 'features/tickets/pages/AllTicketPage/DeleteTicketModal';
import { TicketModal } from 'features/tickets/pages/AllTicketPage/TicketModal';
import { StandUpModal } from 'features/tickets/pages/AllTicketPage/StandUpModal';
// import { LoadingIndicator } from 'components/LoadingIndicator';
// import { Kanban } from '../Kanban';

export const TagButton = styled.button`
  border: 0;
  border-radius: 10px;

  color: white;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding: 3px 10px;
  background-color: #3a3a3a;
`;

const Ticket = React.memo(function Ticket({
  className,
  calendarLoading,
  // ticketLoading,
  isShowStandUpModal,
  toggleStandUpModal,
  events,
  onSelectTicket,
  onClose,
  queryParams,
  projectId,
  onChangeProject,
  onChangeDate,
  onChangeView,
  onChangeDateFilter,
  onCopyLink,
  onDelete,
  onDuplicate,
  isShowDeleteModal,
  onCloseDeleteModal,
  onDeleteTicket,
  onArchive,
  hasRecurrence,
  onSelectedEvent,
  onResetFilterParams,
  // displayedProjects,
  // totalNumOfStories,
  // totalMinutes,
  refetchData,
  refetch,
  projectOptions,
  // isMemberFilter,
  onChangeDueDate,
}) {
  // const hours = Math.floor(totalMinutes / 60);
  // const minutes = totalMinutes % 60;

  return (
    <>
      <div className={className}>
        <Calendar
          className="calendar"
          events={events}
          projectOptions={projectOptions || []}
          onChangeProject={onChangeProject}
          onChangeDate={onChangeDate}
          onChangeView={onChangeView}
          onChangeDateFilter={onChangeDateFilter}
          onCopyLink={onCopyLink}
          onDelete={onDelete}
          onDuplicate={onDuplicate}
          onEdit={onSelectTicket}
          onSelectedEvent={onSelectedEvent}
          onStandUp={toggleStandUpModal}
          queryParams={queryParams}
          onResetFilterParams={onResetFilterParams}
          onChangeDueDate={onChangeDueDate}
          loading={calendarLoading}
          isHideExpandSection
        />
      </div>
      <TicketModal
        isOpen={Boolean(queryParams.selectedTicketId)}
        onClose={onClose}
        ticketId={queryParams.selectedTicketId}
        idEachGroup={queryParams.selectedIdEachGroup}
        onArchive={onArchive}
        projectId={projectId}
        onDuplicate={onDuplicate}
        onRefetch={() => {
          refetchData();
          refetch();
        }}
      />
      <DeleteTicketModal
        isDeleteRepeat={hasRecurrence}
        isOpen={isShowDeleteModal}
        onClose={onCloseDeleteModal}
        onDelete={onDeleteTicket}
        deleteText="Delete this ticket?"
      />
      <StandUpModal isOpen={isShowStandUpModal} onClose={toggleStandUpModal} />
    </>
  );
});

const StyledTicket = styled(Ticket)`
  padding: 0px 20px;
  color: #3a3a3a;

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    padding: unset;
  }

  > .all {
    > .title {
      font-size: 24px;
      font-weight: bold;
    }

    > .kanban {
      overflow: hidden;

      display: flex;
      flex-flow: column nowrap;

      height: calc(100vh - 136px);
      margin-top: 30px;
      padding-bottom: 20px;

      > .board {
        flex-grow: 1;
        display: flex;
        column-gap: 30px;

        padding-top: 0;
        overflow: auto;
      }

      .project {
        flex: 0 0 290px;
        width: 290px;
      }

      @media (max-width: ${theme('breakpoints.tablet')}px) {
        height: calc(100vh - 185px);
      }

      @media (max-width: ${theme('breakpoints.mobile')}px) {
        height: calc(100vh - 235px);
      }
    }
  }
`;

export { StyledTicket as Ticket };
