import React, { useState, useEffect, useMemo } from 'react';
import { theme, prop } from 'styled-tools';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import timeGridPlugin from '@fullcalendar/timegrid';
import { OutlineButton } from 'features/authentication/components/Button';
import { Select } from './Select/Select';
import { useOnClickOutside, useQueryParams } from '../lib/hooks';
import { formatDateString } from '../lib/utils';
import { AvatarList } from './AvatarList';
import {
  EllipseVerticalIcon,
  ArrowUpIcon,
  ClockIcon,
  CalendarxIcon,
} from './Icons';
import { MultiSelect } from './MultiSelect/MultiSelect';
import { SearchSelect } from './SearchSelect';
import { DatePickerFilter } from './DatePickerFilter';
import { ReactComponent as ResetFilter } from '../assets/images/ArrowCounterClockwise.svg';

export const CalendarWrapper = styled.div`
  position: relative;

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    .fc-view-harness.fc-view-harness-active {
      overflow: hidden;
    }

    .fc-scroller-harness .fc-scroller .fc-col-header,
    .fc-scroller-harness .fc-daygrid-body,
    .fc-timegrid-body {
      width: ${prop('width', '370px')} !important;
    }
  }
`;

export const Calendar = React.memo(
  styled(function Calendar({
    className,
    events,
    projectOptions,
    onChangeProject,
    onChangeDate,
    onStandUp,
    onChangeView,
    onChangeDateFilter,
    onCopyLink,
    onDelete,
    onEdit,
    onDuplicate,
    onSelectedEvent,
    onResetFilterParams,
    queryParams,
    onChangeDueDate,
    isHideExpandSection = false,
  }) {
    const [, setQueryParams] = useQueryParams();
    const calendarRef = React.useRef(null);
    const modalRef = React.useRef(null);
    const actionRef = React.useRef(null);
    const { width } = window.screen;
    const [view, setView] = useState(queryParams.view ?? 'timeGridWeek');
    const [startDateFilter, setStartDateFilter] = useState(
      queryParams.startFilter ? DateTime.fromISO(queryParams.startFilter) : null
    );
    const [selectedProjectIds, setSelectedProjectIds] = useState(
      queryParams.projectIds?.split(',') || []
    );
    const [isOpen, setIsOpen] = useState(false);
    const [isExpand, setIsExpand] = useState(false);
    const [triggerData, setTriggerData] = useState(null);
    const [isOpenAction, setIsOpenAction] = useState(false);
    const [height, setHeight] = useState(width);
    const toolbar = document.querySelector('.fc-header-toolbar');
    const toolbarRect = toolbar && toolbar.getBoundingClientRect();

    const viewOptions = [
      { value: 'timeGridDay', label: 'Day' },
      { value: 'timeGridWeek', label: 'Week' },
      { value: 'dayGridMonth', label: 'Month' },
    ];

    const ticketEvents = useMemo(() => {
      if (width < 1023 && view === 'dayGridMonth') {
        return transformEventTicket(events);
      }
      return events;

      // eslint-disable-next-line
    }, [events, width, view]);

    useOnClickOutside(modalRef, () => {
      setIsOpen(false);
      setIsOpenAction(false);
    });

    useOnClickOutside(actionRef, () => {
      setIsOpenAction(false);
    });

    useEffect(() => {
      const element = document.querySelector('.fc-view-harness');
      if (element) {
        element.scrollIntoView();

        if (isExpand) {
          element.classList.add('active');
        } else {
          element.classList.remove('active');
        }
      }
    }, [isExpand]);

    function onSelectStartDateFilter(v) {
      setStartDateFilter(v);
      if (onChangeDateFilter) {
        onChangeDateFilter(v);
      }

      if (v) {
        const dateString = DateTime.fromISO(v).toFormat('yyyy-MM-dd');
        calendarRef.current.getApi().changeView(view, dateString);
      } else {
        const dateString = DateTime.utc().toFormat('yyyy-MM-dd');
        calendarRef.current.getApi().changeView(view, dateString);
      }
    }

    function onResetFilter() {
      const dateString = DateTime.utc().toFormat('yyyy-MM-dd');
      calendarRef.current.getApi().changeView('timeGridWeek', dateString);

      setView('timeGridWeek');
      setStartDateFilter(null);
      setSelectedProjectIds([]);
      if (onResetFilterParams) {
        onResetFilterParams();
      }
    }

    function onSelectView(v) {
      if (onChangeView) {
        onChangeView(v);
      }

      setView(v);
      calendarRef.current.getApi().changeView(v);
    }

    function onExpand() {
      setIsExpand(!isExpand);
    }

    function onSelectProjectIds(id) {
      const index = selectedProjectIds.indexOf(id);

      const updatedProjectIds =
        index !== -1
          ? selectedProjectIds.filter(item => item !== id)
          : [...selectedProjectIds, id];

      setSelectedProjectIds(updatedProjectIds);
      onChangeProject(updatedProjectIds);
    }

    function getDateDisplay(data) {
      const { startDate, endDate, dueDate } = data;
      if (endDate && (startDate || endDate)) {
        return `${dueDate} | ${DateTime.fromISO(startDate).toFormat(
          'hh:mma'
        )} - ${DateTime.fromISO(endDate).toFormat('hh:mma')}`;
      }
      if (dueDate) {
        return dueDate;
      }
      return '-';
    }

    function getTitleDisplay(title) {
      return title.replaceAll('|', '');
    }

    useEffect(() => {
      const harness = document.querySelector('.fc-view-harness.active');

      if (harness) {
        if (width < 1023 && view === 'dayGridMonth') {
          harness.classList.add('fc-dayGridMonth-view');
        } else {
          harness.classList.remove('fc-dayGridMonth-view');
        }
      }

      setHeight(width < 1023 ? toolbarRect?.width : 750);
      // eslint-disable-next-line
    }, [width, view, isExpand, toolbarRect]);

    function onSelectMember(id) {
      setQueryParams({ memberId: id });
    }

    return (
      <div className={className}>
        <div className="content-calendar">
          <CalendarxIcon className="icon" />
          <OutlineButton
            className="button standup-button"
            type="button"
            onClick={onStandUp}
          >
            Stand up
          </OutlineButton>
          <div className="filter">
            <DatePickerFilter
              className="datepicker"
              value={startDateFilter}
              placeholder="Jump to date"
              onChange={onSelectStartDateFilter}
              isClearable
              showDateIcon
              hiddenArrowIcon
            />
            <SearchSelect onChange={onSelectMember} />
            {projectOptions && projectOptions.length > 0 && (
              <MultiSelect
                options={projectOptions}
                onChange={onSelectProjectIds}
                value={selectedProjectIds}
                placeholder="All project"
              />
            )}

            <Select
              options={viewOptions}
              value={view}
              onChange={e => onSelectView(e.value)}
            />
            <ResetFilter className="reset-filter" onClick={onResetFilter} />
          </div>
          <CalendarWrapper width={`${toolbarRect?.width}px`}>
            <FullCalendar
              ref={calendarRef}
              datesSet={dateInfo => {
                onChangeDate(dateInfo.start, dateInfo.end);
              }}
              plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
              eventClick={e => {
                setTriggerData(e);
                setIsOpen(true);
                onSelectedEvent(e.event.extendedProps.hasRecurrence);
              }}
              editable
              droppable
              eventReceive={info => {
                onChangeDueDate(info);
              }}
              eventChange={changeInfo => {
                onChangeDueDate(changeInfo);
              }}
              initialDate={queryParams.start}
              initialView={view}
              initialEvents={ticketEvents}
              events={ticketEvents}
              headerToolbar={{
                left: 'title,prev,next',
                center: '',
                right: '',
              }}
              slotDuration="01:00"
              snapDuration="00:15:00"
              allDaySlot={view === 'timeGridDay' || view === 'timeGridWeek'}
              slotLabelFormat={{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: true,
                meridiem: true,
              }}
              displayEventTime
              displayEventEnd
              fixedWeekCount={false}
              showNonCurrentDates={false}
              dayMaxEventRows={false}
              dayMaxEvents={false}
              contentHeight={height}
              aspectRatio={3.5}
              expandRows
              eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                meridiem: false,
              }}
              moreLinkContent={args => `${args.num} More`}
              views={{
                timeGridDay: {
                  dayMaxEventRows: 3,
                  titleFormat: { year: 'numeric', month: 'long' },
                  dayHeaderFormat: { day: 'numeric', weekday: 'long' },
                  dayHeaderContent: ({ date }) => {
                    return {
                      html: `<div>
                   <div class="fc-week-text">${formatDateString(date, {
                     weekday: 'long',
                   })}</div><div>${formatDateString(date)}</div></div>`,
                    };
                  },
                },
                timeGridWeek: {
                  dayMaxEventRows: 3,
                  titleFormat: { year: 'numeric', month: 'long' },
                  dayHeaderContent: ({ date }) => {
                    return {
                      html: `<div>
                   <div class="fc-week-text">${formatDateString(date, {
                     weekday: 'short',
                   })}</div><div>${formatDateString(date)}</div></div>`,
                    };
                  },
                },
                dayGridMonth: {
                  dayMaxEventRows: 3,
                },
              }}
            />
            {isOpen && (
              <div
                className="modal"
                ref={modalRef}
                style={{
                  top: triggerData.jsEvent.pageY - 200,
                  left:
                    width < 1023
                      ? triggerData.jsEvent.pageX / 2 / 2
                      : triggerData.jsEvent.pageX / 2,
                }}
              >
                <div className="header">
                  <div className="sub">
                    <div>#{triggerData.event.extendedProps.idEachGroup}</div>
                    {triggerData.event.extendedProps.hasEdit && (
                      <div className="menu" ref={actionRef}>
                        <div
                          className="menu-icon"
                          onClick={e => {
                            e.stopPropagation();
                            setIsOpenAction(true);
                          }}
                        >
                          <EllipseVerticalIcon />
                        </div>
                        {isOpenAction && (
                          <div className="menu-list">
                            <div
                              className="menu-item"
                              onClick={e => {
                                e.stopPropagation();
                                setIsOpen(false);

                                setIsOpenAction(false);
                                onCopyLink(
                                  triggerData.event.id,
                                  triggerData.event.extendedProps.idEachGroup,
                                  triggerData.event.extendedProps.projectId
                                );
                              }}
                            >
                              Copy link
                            </div>
                            <div
                              className="menu-item"
                              onClick={e => {
                                e.stopPropagation();
                                setIsOpen(false);
                                setIsOpenAction(false);
                                onDuplicate(
                                  triggerData.event.id,
                                  triggerData.event.extendedProps.projectId
                                );
                              }}
                            >
                              Duplicate
                            </div>
                            <div
                              className="menu-item"
                              onClick={e => {
                                e.stopPropagation();
                                setIsOpen(false);
                                setIsOpenAction(false);
                                onDelete(
                                  triggerData.event.id,
                                  triggerData.event.extendedProps.projectId
                                );
                              }}
                            >
                              Delete
                            </div>
                            <div
                              className="menu-item"
                              onClick={e => {
                                e.stopPropagation();
                                setIsOpen(false);
                                setIsOpenAction(false);
                                onEdit(
                                  triggerData.event.id,
                                  triggerData.event.extendedProps.idEachGroup,
                                  triggerData.event.extendedProps.projectId
                                );
                              }}
                            >
                              Edit
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="title">
                    {getTitleDisplay(triggerData.event.title)}
                  </div>
                </div>
                <div className="footer">
                  <div>
                    <ClockIcon />
                    {getDateDisplay(triggerData.event.extendedProps)}
                  </div>
                  <AvatarList
                    className="assignees"
                    data={triggerData.event.extendedProps.members}
                    userCount={triggerData.event.extendedProps.userCount}
                    compact
                    hidePlaceholder
                  />
                </div>
              </div>
            )}
          </CalendarWrapper>
        </div>
        {!isHideExpandSection && (
          <div className="expand">
            <div className="line" />
            <div
              className={`icon ${isExpand ? 'active' : ''}`}
              onClick={() => onExpand()}
            >
              <ArrowUpIcon />
            </div>
          </div>
        )}
      </div>
    );
  })`
    position: relative;

    .content-calendar {
      font-family: Arial;
      border-radius: 10px;
      background: white;

      > .standup-button {
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 2;
      }

      > .icon {
        width: 30px;
        height: 30px;
        position: absolute;
        left: 0;
        margin-top: 25px;
        margin-left: 20px;
      }
    }

    .menu {
      position: relative;

      .menu-icon {
        color: #ddd;
        cursor: pointer;
      }

      .menu-list {
        border: 0;
        min-width: 80px;
        border-radius: 6px;
        padding: 6px 0;
        position: absolute;
        background: white;
        z-index: 1;

        box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.15);

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          right: 0;
        }
      }

      .menu-item {
        padding: 5px 10px;
        font-weight: normal;
        cursor: pointer;
        color: ${theme('colors.black')};

        &:hover {
          color: ${theme('colors.black')};
          background: #efefef;
        }
      }
    }

    .fc-scrollgrid-sync-table tr {
      height: 120px;
    }

    .fc-toolbar-title {
      margin-left: 35px;
    }

    .fc-view-harness {
      height: 750px !important;

      &.active {
        height: 190px !important;
        overflow: hidden;
      }
    }

    .fc-toolbar.fc-header-toolbar {
      margin-bottom: 5rem;
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      .fc-view-harness.active.fc-dayGridMonth-view {
        height: 150px !important;
        overflow: hidden;
      }
    }

    .fc-timeGridDay-view .fc-col-header-cell .fc-scrollgrid-sync-inner {
      text-align: left;

      .fc-col-header-cell-cushion {
        text-align: center;
      }
    }

    .fc-week-text {
      padding-bottom: 5px;
    }

    .fc-day-today,
    .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame {
      background-color: rgba(34, 199, 89, 0.1) !important;
    }

    .fc-daygrid-day.fc-day-disabled,
    .fc-popover.fc-more-popover.fc-day.fc-day-today {
      background-color: white !important;
    }

    .fc-timegrid-col.fc-day-today {
      background: unset !important;
    }

    .filter {
      position: absolute;
      top: 3.5rem;
      background: transparent;
      left: 20px;
      margin-top: 20px;
      z-index: 9;
      background: white;
      margin-right: 20px;
      display: flex;
      column-gap: 10px;

      > div:nth-child(3) {
        width: 170px;
      }

      > div:nth-child(2) {
        width: 200px;
      }

      .reset-filter {
        cursor: pointer;
        color: #c1c3ca;
        margin-top: 5px;
        width: 26px;
        height: 26px;

        &:hover {
          color: ${theme('colors.primary')};
        }
      }
    }
    @media (max-width: ${theme('breakpoints.mobile')}px) {
      .fc-timegrid-divider {
        display: none;
      }
    }

    .fc-dayGridMonth-view .fc-daygrid-dot-event .fc-event-title {
      padding-left: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: normal;
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      .fc-dayGridMonth-view {
        .fc-daygrid-event-dot {
          margin-left: 0;
        }
        .fc-daygrid-event-harness {
          margin: 2px 2px;
        }

        .fc-event-title {
          font-size: 10px;
          padding-left: unset !important;
          text-overflow: unset !important;
        }
      }
      .fc-popover-body .fc-event-time,
      .fc-dayGridMonth-view .fc-event-time {
        display: none;
      }

      .fc-dayGridMonth-view .fc-event-title {
        padding-left: unset;
      }
    }

    .fc-timeGridDay-view,
    .fc-timeGridWeek-view {
      .fc-event-title-container {
        display: flex;
        align-items: center;
        width: 100%;
      }
      .fc-event-title {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .fc-timeGridDay-view table.fc-scrollgrid > tbody > tr:first-child,
    .fc-timeGridWeek-view table.fc-scrollgrid > tbody > tr:first-child {
      .fc-daygrid-day-bottom {
        margin-top: unset !important;
      }
      .fc-scroller-harness {
        height: 65px;

        .fc-event-title {
          font-weight: bold;
        }
      }
      .fc-timegrid-axis-frame {
        height: 65px;
        font-size: 14px;
        font-weight: bold;
      }
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      .fc-timeGridWeek-view table.fc-scrollgrid > tbody > tr:first-child {
        .fc-daygrid-day-bottom {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 40px;
          white-space: nowrap;
        }
      }
    }

    .fc-timegrid .fc-event-title {
      font-size: 12px;
    }

    .fc-timeGridWeek-view .fc-timegrid-event {
      text-align: center;
    }

    .fc-timeGridWeek-view .fc-event-title-container {
      justify-content: center;
    }

    .fc-timeGridDay-view .fc-event-main-frame {
      align-items: center;
    }

    .fc-daygrid-day-top {
      display: flex;
      width: 100%;
      font-weight: bold;
      justify-content: center;

      a {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .fc-daygrid-day-bottom {
      position: absolute;
      margin-top: 0.25rem !important;

      @media (max-width: ${theme('breakpoints.mobile')}px) {
        margin-top: unset !important;
      }

      .fc-daygrid-more-link {
        text-decoration-line: underline;
        color: #3a3a3a;
        font-size: 12px;
        padding-left: 2px;
      }
    }

    .fc-dayGridMonth-view .fc-col-header th.fc-col-header-cell {
      height: 36px;
    }

    .fc-toolbar-chunk:nth-child(1) div {
      display: flex;
      column-gap: 10px;
      align-items: center;
      margin-left: 20px;
      margin-top: 20px;
    }

    .fc-toolbar-chunk .fc-prev-button,
    .fc-toolbar-chunk .fc-next-button {
      height: 24px;
      width: 24px;
      background: #3a3a3a;
      vertical-align: middle;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .fc-toolbar-chunk .fc-prev-button .fc-icon,
    .fc-toolbar-chunk .fc-next-button .fc-icon {
      font-size: 20px;
    }

    .fc-col-header th.fc-col-header-cell {
      height: 65px;
      vertical-align: middle;
    }
    .fc-timeGridDay-view .fc-timegrid-event-harness,
    .fc-timegrid-event-harness {
      font-weight: bold;

      .fc-event-main-frame {
        align-items: center;
      }

      .fc-event-time {
        display: none;
      }
    }

    .fc-day-today .fc-daygrid-day-events .fc-daygrid-event-harness {
      color: #3a3a3a;
      background-color: transparent;
    }

    .fc-timeGridDay-view .fc-timegrid-event-harness .fc-event-main-frame {
      align-items: unset;
      padding-left: 10px;
    }

    .fc-timegrid-slot {
      font-size: 14px;
      font-weight: bold;
      color: #3a3a3a;
    }

    .fc-timegrid-slots tbody tr {
      height: 55px;
    }

    .fc-view {
      height: 730px;
    }

    .modal {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 300px;
      height: 155px;
      background: white;
      padding: 20px;
      box-shadow: 0 5px 10px 0 rgb(58 58 58 / 10%);
      z-index: 9999;
      font-size: 14px;
      font-weight: bold;
      color: #3a3a3a;

      .title {
        font-weight: normal;
      }

      .sub {
        display: flex;
        justify-content: space-between;
      }

      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #9fa3ae;
        font-size: 12px;

        svg {
          width: 14px;
          height: 14px;
          margin-right: 3px;
        }
      }
    }

    .expand {
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 18px;

      .line {
        width: 100%;
        height: 1px;
        margin: 15px 0 14px;
        border: solid 1px #dce0ea;
        background-color: #3a3a3a;
      }

      .icon {
        width: 30px;
        height: 30px;
        background: white;
        border-radius: 50%;
        border: solid 1px #dce0ea;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;

        svg {
          width: 20px;
          height: 20px;
        }

        &.active {
          transform: rotate(180deg);
        }
      }
    }
  `
);

function transformEventTicket(events) {
  return events.map(event => ({
    ...event,
    title:
      event.extendedProps.startDate || event.extendedProps.endDate
        ? `${event.extendedProps.startDate} - ${event.extendedProps.endDate} ${event.title}`
        : event.title,
  }));
}
